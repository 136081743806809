import React, { useState } from 'react';
import { useCart } from '../../context/cartContext';
import { useAuth } from '../../context/authContext';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {  Modal } from 'antd'; 
import 'moment/locale/es'; // Importa el locale en español

const OrderSummary = ({ details }) => {
    const { cart, clearCart } = useCart();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loadingOrder, setLoadingOrder] = useState(false)

    const methodTranslation = {
        'store-pickup': 'Recoger en tienda',
        'foreign-shipping': 'Envío foráneo',
        'package-shipping': 'Envío a domicilio',
        'pickup-point': 'Entrega en punto fijo',
        'national-shipping': 'Paquetería'
    };

    const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const shippingCost = details.method === 'store-pickup' 
    ? 0 
    : details.method === 'foreign-shipping' 
    ? 0 
    : details.method === 'package-shipping' 
    ? 50 
    : details.method === 'national-shipping' 
    ? 350 
    : 0;    const total = subtotal + shippingCost;



// const formattedDeliveryDate = details.deliveryDate
//     ? moment(details.deliveryDate, 'YYYY-MM-DD').isValid()
//         ? moment(details.deliveryDate, 'YYYY-MM-DD').format(
//               details.method === 'pickup-point' || details.method === 'package-shipping'
//                   ? 'dddd, D [de] MMMM [de] YYYY [a las 12:00pm]'
//                   : 'dddd, D [de] MMMM [de] YYYY'
//           )
//         : 'Fecha inválida'
//     : null;

const formattedDeliveryDate = details.deliveryDate
    ? moment(details.deliveryDate, 'YYYY-MM-DD').isValid()
        ? moment(details.deliveryDate, 'YYYY-MM-DD').format(
              details.method === 'pickup-point'
                  ? 'dddd, D [de] MMMM [de] YYYY [a las 12:00pm]'
                  : 'dddd, D [de] MMMM [de] YYYY'
          )
        : 'Fecha inválida'
    : null;

const handleGoBack = () => {
    navigate('/cart');
};

    // const saveOrderToDatabase = async () => {
    //     const checkForDuplicateOrder = async () => {
    //         const { data, error } = await supabase
    //             .from('orders')
    //             .select('*')
    //             .eq('user_id', details.userId || null)
    //             .eq('method', details.method)
    //             .gte('created_at', moment().subtract(10, 'seconds').toISOString());
        
    //         if (error) throw error;
    //         if (data.length > 0) {
    //             throw new Error('Ya has realizado un pedido similar recientemente.');
    //         }
    //     };
        
    //     try {
    //         // Verificar que el carrito tenga productos
    //         if (!cart || cart.length === 0) {
    //             throw new Error('El carrito está vacío. No se puede crear un pedido.');
    //         }

    //         // Verificar que el total sea mayor a 0
    //         if (total <= 0) {
    //             throw new Error('El total del pedido es inválido.');
    //         }

    //         // Verificar si se ha seleccionado un método de entrega válido
    //         if (!details.method) {
    //             throw new Error('No se ha seleccionado un método de entrega.');
    //         }
            
    //         const items = cart.map(item => ({
    //             id: item.id,
    //             name: item.name,
    //             quantity: item.quantity,
    //             price: item.price,
    //             notes: item.notes || ''
    //         }));
    
    //         const deliveryDateForDB = moment(details.deliveryDate).format('YYYY-MM-DD');
            
    //         // Consulta para verificar si ya existe un pedido duplicado reciente
    //         const { data: existingOrders, error: checkError } = await supabase
    //             .from('orders')
    //             .select('*')
    //             .eq('user_id', user.id)
    //             .eq('method', details.method)
    //             .eq('total', total)
    //             .gte('created_at', moment().subtract(10, 'seconds').toISOString()) // Verifica si ya existe un pedido en los últimos 10 segundos
    //             .limit(1);  // Limitamos la consulta a un solo pedido
    
    //         if (checkError) throw checkError;
    
    //         // Si ya existe un pedido similar, no procedemos con la inserción
    //         if (existingOrders.length > 0) {
    //             throw new Error('Ya has creado un pedido similar recientemente.');
    //         }
    
    //         // Generar el folio consecutivo
    //         const orderFolio = await generateFolio(); 
    
    //         // Si no existe un pedido similar, procedemos a guardar el nuevo pedido
    //         const { data: orderData, error: orderError } = await supabase
    //             .from('orders')
    //             .insert([{
    //                 user_id: user.id,
    //                 delivery_date: deliveryDateForDB,
    //                 method: details.method,
    //                 store: details.store,
    //                 address: details.address,
    //                 items: items,
    //                 total: total,
    //                 subtotal: subtotal,
    //                 shipping_cost: shippingCost,
    //                 hour: details.hour,
    //                 folio: orderFolio,
    //                 print_status: false // Asegurar que al crear el pedido, no está impreso

    //             }]);
    
    //         if (orderError) throw orderError;
    
    //         // Actualizamos el stock de cada producto vendido
    //         for (let item of cart) {
    //             await updateProductStock(item.id, item.quantity);
    //         }
    
    //         clearCart();
    //         navigate('/order-placed', {
    //             state: {
    //                 orderDetails: {
    //                     folio: orderFolio,
    //                     store: details.store,
    //                     address: details.address,
    //                     method: details.method
    //                 },
    //                 formattedDeliveryDate,
    //             },
    //         });
    
    //     } catch (error) {
    //         console.error('Error al procesar el pedido:', error.message);
    //         window.alert(`Error: ${error.message}`);
    //     } finally {
    //         setLoadingOrder(false); // Deshabilita el loader cuando el pedido se haya procesado
    //     }
    // };

    const saveOrderToDatabase = async () => {
        const checkForDuplicateOrder = async () => {
            const { data, error } = await supabase
                .from('orders')
                .select('*')
                .eq('user_id', details.userId || null)
                .eq('method', details.method)
                .gte('created_at', moment().subtract(10, 'seconds').toISOString());
        
            if (error) throw error;
            if (data.length > 0) {
                throw new Error('Ya has realizado un pedido similar recientemente.');
            }
        };
    
        try {
            // Verificar que el carrito tenga productos
            if (!cart || cart.length === 0) {
                throw new Error('El carrito está vacío. No se puede crear un pedido.');
            }
    
            // Verificar que el total sea mayor a 0
            if (total <= 0) {
                throw new Error('El total del pedido es inválido.');
            }
    
            // Verificar si se ha seleccionado un método de entrega válido
            if (!details.method) {
                throw new Error('No se ha seleccionado un método de entrega.');
            }
    
            // Validar el stock de cada producto antes de continuar
            for (let item of cart) {
                try {
                    await updateProductStock(item.id, item.quantity);
                } catch (stockError) {
                    // Manejo específico del error de stock insuficiente
                    Modal.error({
                        title: 'Error de stock insuficiente',
                        content: `No hay suficiente stock para el producto ID: ${item.id}.`,
                        onOk: () => navigate(-1), // Regresar al paso anterior
                    });
                    return; // Terminar ejecución si hay un error en el stock
                }
            }
    
            const items = cart.map(item => ({
                id: item.id,
                name: item.name,
                quantity: item.quantity,
                price: item.price,
                notes: item.notes || ''
            }));
    
            const deliveryDateForDB = moment(details.deliveryDate).format('YYYY-MM-DD');
    
            // Consulta para verificar si ya existe un pedido duplicado reciente
            const { data: existingOrders, error: checkError } = await supabase
                .from('orders')
                .select('*')
                .eq('user_id', user.id)
                .eq('method', details.method)
                .eq('total', total)
                .gte('created_at', moment().subtract(10, 'seconds').toISOString()) // Verifica si ya existe un pedido en los últimos 10 segundos
                .limit(1);  // Limitamos la consulta a un solo pedido
    
            if (checkError) throw checkError;
    
            // Si ya existe un pedido similar, no procedemos con la inserción
            if (existingOrders.length > 0) {
                throw new Error('Ya has creado un pedido similar recientemente.');
            }
    
            // Generar el folio consecutivo
            const orderFolio = await generateFolio(); 
    
            // Si no existe un pedido similar, procedemos a guardar el nuevo pedido
            const { data: orderData, error: orderError } = await supabase
                .from('orders')
                .insert([{
                    user_id: user.id,
                    delivery_date: deliveryDateForDB,
                    method: details.method,
                    store: details.store,
                    address: details.address,
                    items: items,
                    total: total,
                    subtotal: subtotal,
                    shipping_cost: shippingCost,
                    hour: details.hour,
                    folio: orderFolio,
                    print_status: false // Asegurar que al crear el pedido, no está impreso
                }]);
    
            if (orderError) throw orderError;
    
            clearCart();
            navigate('/order-placed', {
                state: {
                    orderDetails: {
                        folio: orderFolio,
                        store: details.store,
                        address: details.address,
                        method: details.method
                    },
                    formattedDeliveryDate,
                },
            });
    
        } catch (error) {
            console.error('Error al procesar el pedido:', error.message);
            Modal.error({
                title: 'Error al procesar el pedido',
                content: `${error.message}`,
                onOk: () => navigate(-1), // Regresar al paso anterior
            });
        } finally {
            setLoadingOrder(false); // Deshabilita el loader cuando el pedido se haya procesado
        }
    };
    

    const generateFolio = async () => {
        try {
            // Bloquear la transacción mientras se consulta el último folio
            const { data: lastOrder, error: lastOrderError } = await supabase
                .from('orders')
                .select('folio')
                .order('created_at', { ascending: false }) // Ordenar por fecha de creación
                .limit(1);
    
            if (lastOrderError) {
                console.error('Error al obtener el último pedido:', lastOrderError);
                throw lastOrderError;
            }
    
            let newFolioNumber;
            if (lastOrder && lastOrder.length > 0) {
                let lastFolio = lastOrder[0].folio;
                
                console.log('Último folio encontrado:', lastFolio); // Log para verificar el último folio
    
                // Eliminar cualquier prefijo no numérico del folio (por ejemplo, letras)
                lastFolio = lastFolio.replace(/\D/g, ''); // Remover todas las letras
                
                // Verificar si el último folio es un número válido después de eliminar el prefijo
                const lastFolioNumber = parseInt(lastFolio, 10);
    
                if (isNaN(lastFolioNumber)) {
                    console.warn('El último folio no es un número válido, iniciando desde 1.');
                    newFolioNumber = 1;
                } else {
                    newFolioNumber = lastFolioNumber + 1; // Incrementar el último número del folio
                }
            } else {
                console.warn('No se encontraron pedidos previos, iniciando desde 1.');
                newFolioNumber = 1; // Si no hay pedidos previos, empezar desde 1
            }
    
            // Retornar el nuevo folio como un número de 7 dígitos
            const formattedFolio = String(newFolioNumber).padStart(7, '0'); // Ahora 7 dígitos
            console.log('Nuevo folio generado:', formattedFolio);
    
            return formattedFolio;
    
        } catch (error) {
            console.error('Error generando el folio:', error);
            throw error;
        }
    };


    // Función para actualizar el stock de un producto
    // const updateProductStock = async (productId, quantitySold) => {
    //     console.log(`Actualizando stock para el producto ID: ${productId} con cantidad vendida: ${quantitySold}`);

    //     const { data: product, error: productError } = await supabase
    //         .from('products')
    //         .select('pieces')
    //         .eq('id', productId)
    //         .single();

    //     if (productError) {
    //         console.error('Error al obtener el stock:', productError);
    //         throw productError;
    //     }

    //     console.log('Producto obtenido:', product);

    //     const currentStock = Number(product.pieces);
    //     const soldQuantity = Number(quantitySold);
    //     const newStock = currentStock - soldQuantity;
    //     console.log(`Nuevo stock calculado: ${newStock}`);

    //     if (newStock < 0) {
    //         throw new Error(`No hay suficiente stock para el producto ID: ${productId}`);
    //     }

    //     const { data: updateData, error: updateError } = await supabase
    //         .from('products')
    //         .update({ pieces: newStock })
    //         .eq('id', productId);

    //     if (updateError) {
    //         console.error('Error al actualizar el stock:', updateError);
    //         throw updateError;
    //     } else {
    //         console.log('Stock actualizado correctamente. Datos actualizados:', updateData);
    //     }
    // };
    const updateProductStock = async (productId, quantitySold) => {
        console.log(`Actualizando stock para el producto ID: ${productId} con cantidad vendida: ${quantitySold}`);
    
        const { data: product, error: productError } = await supabase
            .from('products')
            .select('pieces')
            .eq('id', productId)
            .single();
    
        if (productError) {
            console.error('Error al obtener el stock:', productError);
            throw new Error('Error al consultar el stock del producto.');
        }
    
        console.log('Producto obtenido:', product);
    
        const currentStock = Number(product.pieces);
        const soldQuantity = Number(quantitySold);
        const newStock = currentStock - soldQuantity;
    
        console.log(`Nuevo stock calculado: ${newStock}`);
    
        if (newStock < 0) {
            throw new Error(`No hay suficiente stock para el producto ID: ${productId}.`);
        }
    
        const { data: updateData, error: updateError } = await supabase
            .from('products')
            .update({ pieces: newStock })
            .eq('id', productId);
    
        if (updateError) {
            console.error('Error al actualizar el stock:', updateError);
            throw new Error('Error al actualizar el stock del producto.');
        } else {
            console.log('Stock actualizado correctamente. Datos actualizados:', updateData);
        }
    };
    

    const [acceptedPolicies, setAcceptedPolicies] = useState(true);

    const handleCheckboxChange = (e) => {
        setAcceptedPolicies(e.target.checked);
    };

    const handleOrderSubmit = () => {
        if (cart.length === 0) {
            alert('No puedes realizar un pedido con un carrito vacío.');
            return; // Detener la ejecución si el carrito está vacío
        }

        if (acceptedPolicies && !loadingOrder) {
            setLoadingOrder(true); // Habilita el loader y desactiva el botón
            setTimeout(() => {
                setLoadingOrder(false); // Reactivar el botón después de 30 segundos
            }, 5000);
            saveOrderToDatabase(); // Llama a la función para guardar el pedido y actualizar el stock
        } else if (!acceptedPolicies) {
            alert('Debes aceptar las políticas de venta antes de realizar el pedido.');
        }
    };

    return (
        <section className='mt-20 mb-75'>
            <div className="container mtb-20">
                <button className="btn btn-yellow" onClick={handleGoBack}>
                    Regresar
                </button>
                <div className='total-card bold color-grey'>
                    <div className='mb-16'>Subtotal</div>
                    <div className='txt-align-rigth'>${subtotal.toFixed(2)}</div>
                    <div className='mb-16'>Envio</div>
                    <div className='txt-align-rigth'>
                        {shippingCost === 0 ? 'Gratis' : `$${shippingCost.toFixed(2)}`}
                    </div>
                    <div className='color-black'>Total</div>
                    <div className='txt-align-rigth color-black'>${total.toFixed(2)}</div>
                </div>
                <div className='card-white'>
                    <h2 className='mb-16'>{methodTranslation[details.method] || details.method}</h2>
                    <div className='bold mb-16'>Nombre y apellido:</div>
                    <p className='mb-16'>{user?.user_metadata?.full_name || 'No disponible'}</p>
                    <div className='bold mb-16'>Teléfono de contacto:</div>
                    <p className='mb-16'>{user.phone || 'No disponible'}</p>

                    {/* Mostrar la fecha de entrega solo si el método no es 'national-shipping' */}
                    {details.method !== 'national-shipping' && (
                        <>
                            <div className='bold mb-16'>Fecha de entrega:</div>
                            <p className='mb-16'>{formattedDeliveryDate}</p>
                        </>
                    )}

                    {details.store && (
                        <>
                            <div className='bold mb-16'>Recoger en:</div>
                            <p className='mb-16'>{details.store}</p>
                        </>
                    )}
                    {details.address && (
                        <>
                            <div className='bold mb-16'>Dirección de entrega:</div>
                            <p className='mb-16'>{details.address}</p>
                        </>
                    )}

                    {/* Mostrar leyenda adicional si el método de envío es "national-shipping" */}
                    {details.method === 'national-shipping' && (
                        <div className="alert alert-info mtb-20">
                            <p className='mtb-16'><strong>Nota:</strong> Una vez pagado tu pedido, se enviará al siguiente día hábil de las 12:00p.m. </p>
                            <p>Días hábiles : Lunes a Jueves</p>
                        </div>
                    )}
                    {details.hour && (
                        <>
                            <div className='bold mb-16'>Horario de entrega:</div>
                            <p className='mb-16'>{details.hour}</p>
                        </>
                    )}
                </div>
                <ul>
                    {cart.map(item => (
                        <li className='card-white f-h mtb-16' key={item.id}>
                            <div className='item-image-wrapper'>
                                <img className='item-image' src={item.image_url} alt={item.name} />
                            </div>
                            <div className='card-description'>
                                <h3 className='mb-16'>{item.name}</h3>
                                <p>Costo unitario: ${item.price}</p>
                                <div className='f-h jc-sb bold mt-90'>
                                    Cantidad: {item.quantity}
                                    <p>${(item.quantity * item.price).toFixed(2)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="policies-checkbox mtb-20">
                    <input
                        type="checkbox"
                        id="acceptPolicies"
                        checked={acceptedPolicies} // El checkbox estará marcado inicialmente
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="acceptPolicies">
                        Acepto las <a href="/policies">políticas de venta</a>
                    </label>
                </div>
                <button
                    className='btn mtb-20'
                    onClick={handleOrderSubmit}
                    disabled={loadingOrder || !acceptedPolicies} // Desactivar cuando esté cargando o no haya aceptado políticas
                >
                    {loadingOrder ? 'Procesando...' : 'Finalizar compra'}
                </button>
            </div>
        </section>
    );
};

export default OrderSummary;