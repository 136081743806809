import React from 'react';
import { Carousel } from 'antd';

const Slider = () => {
    const textSlides = [
        {
            title: "Bienvenidos a MRT comercializadora",
            subtitle: "Somos importadores directos",
            link: "#",
        },
        {
            title: "Somos importadores directos",
            subtitle: "Precio de mayoreo desde 1 pza",
            link: "#",
        },
        {
            title: "Actualización cada semana",
            subtitle: "Muchos productos nuevos",
            link: "#",
        },
    ];

    const imageSlides = [
        "https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/imagen-1.jpg",
        "https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/imagen-2.jpg",
        "https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/imagen-3.jpg",
    ];

    return (
        <div className='slider-container'>
            {/* Primer Slider: Texto */}
            <div className="slider">
                <Carousel autoplay arrows infinite>
                    {textSlides.map((slide, index) => (
                        <div key={index} className="panel2 slide-one">
                            <h1>{slide.title}</h1>
                            <h2>{slide.subtitle}</h2>
                            <a className='btn btn-yellow' href={slide.link}>Conócenos</a>
                        </div>
                    ))}
                </Carousel>
            </div>

            {/* Segundo Slider: Imágenes */}
            <div className="slider-2">
                <Carousel autoplay arrows infinite>
                    {imageSlides.map((image, index) => (
                        <div key={index} className="panel2">
                            <img
                                src={image}
                                alt={`Imagen ${index + 1}`}
                                style={{ width: "100%", height: "300px", objectFit: "cover" }}
                            />
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default Slider;