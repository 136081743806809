import React, { useState, useEffect } from 'react';
import { Button, Modal, DatePicker } from 'antd'; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import DropdownForeign from './DropdownForeign';
import DropdownDelivery from './DropDownDelivery';
import { useLocation } from 'react-router-dom';

import DropDownPickUp from './DropDownPickUp';
import moment from 'moment';
import 'moment/locale/es'; 

const ShippingMethod = ({ onNext}) => {    
    const location = useLocation();
    const cartTotal = location.state?.cartTotal || 0;
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [selectedStore, setSelectedStore] = useState(''); 
    const [selectedAddress, setSelectedAddress] = useState(''); 
    const [selectedHour, setSelectedHour] = useState(''); // Nuevo estado para el horario
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(null); // Se cambia a null inicialmente
    const navigate = useNavigate();
    const { user } = useAuth();
    const [name, setName] = useState(user ? user.user_metadata.full_name : '');

    useEffect(() => {
        console.log("Cart Total:", cartTotal);
    }, [cartTotal]);

// Cálculo de fecha para "foreign-shipping"
useEffect(() => {
    if (selectedMethod === 'foreign-shipping' && selectedAddress) {
        const today = moment();
        let deliveryDate = moment(today);

        if (selectedAddress.includes('Actopan')) {
            // Calcular el próximo jueves
            deliveryDate.add(1, 'days'); // Iniciar un día después
            while (deliveryDate.day() !== 4) {
                deliveryDate.add(1, 'days');
            }
        } else if (selectedAddress.includes('Tulancingo') || selectedAddress.includes('Cd. Sahagún')) {
            // Calcular el próximo viernes
            deliveryDate.add(1, 'days'); // Iniciar un día después
            while (deliveryDate.day() !== 5) {
                deliveryDate.add(1, 'days');
            }
        }
        setDeliveryDate(deliveryDate);
    }
}, [selectedMethod, selectedAddress]);


// Cálculo de fecha para "pickup-point"
useEffect(() => {
    if (selectedMethod === 'pickup-point') {
        const today = moment();
        let deliveryDay = moment(today);
        // Calcular el próximo martes
        deliveryDay.add(1, 'days'); // Iniciar un día después
        while (deliveryDay.day() !== 2) {
            deliveryDay.add(1, 'days');
        }
        setDeliveryDate(deliveryDay);
    }
}, [selectedMethod]);

    // Cálculo de fecha para "store-pickup"
    const getNextBusinessDay = () => {
        const today = moment();
        let nextBusinessDay = moment(today).add(1, 'days');
        while (nextBusinessDay.day() === 0) { // Evitar domingos
            nextBusinessDay.add(1, 'days');
        }
        return nextBusinessDay;
    };



    const handleSelectMethod = (method) => {
        if (method === 'package-shipping' && Number(cartTotal) < 200) {
            Modal.warning({
                title: 'Monto mínimo requerido',
                content: 'El monto mínimo para el envío a domicilio es de $200. Agrega más productos al carrito para habilitar esta opción.',
            });
            return;
        }
    
        setSelectedMethod(method);
    
        if (method === 'store-pickup') {
            const nextBusinessDay = getNextBusinessDay();
            setDeliveryDate(nextBusinessDay);
            setSelectedAddress("Siembra 193, Pachuca, México"); // Dirección fija para recoger en tienda
        } else if (method !== 'package-shipping' && method !== 'foreign-shipping' && method !== 'pickup-point' && method !== 'national-shipping') {
            setDeliveryDate(null);
            setSelectedAddress('');

        }
        console.log(cartTotal)
    };

    const disabledDateForPackageShipping = (current) => {
        const today = moment().startOf('day');
        const isBeforeToday = current && current < today;
        
        // Solo permitir los próximos 3 días hábiles (sin contar sábado y domingo)
        const nextThreeBusinessDays = [];
        let day = today.clone().add(1, 'day');
        while (nextThreeBusinessDays.length < 3) {
            if (day.day() !== 0 && day.day() !== 6) {
                nextThreeBusinessDays.push(day.clone());
            }
            day.add(1, 'day');
        }
    
        const isValidDay = nextThreeBusinessDays.some(validDay => current.isSame(validDay, 'day'));
    
        return isBeforeToday || !isValidDay;
    };

    const disabledDate = (current) => {
        const today = moment().startOf('day');
        const isTooEarly = current && current < today;
        const isTooLate = current && current > moment(today).add(3, 'days').endOf('day'); // Permitir solo 3 días después de hoy
        const isNotAllowedDay = current && (current.day() === 0 || current.day() === 5 || current.day() === 6); // Excluir viernes, sábado y domingo
        
        return isTooEarly || isTooLate || isNotAllowedDay;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!user) {
            setIsModalOpen(true);
        } else {
            if (selectedMethod) {
                // Validación para el método de envío a domicilio con monto mínimo
                if (selectedMethod === 'package-shipping' && cartTotal < 200) {
                    Modal.warning({
                        title: 'Monto mínimo requerido',
                        content: 'El monto mínimo para el envío a domicilio es de $200. Agrega más productos al carrito para poder continuar.',
                    });
                    return;
                }
    
                let deliveryDateToSave = null;
    
                // Si la entrega requiere fecha y está seleccionada, se guarda
                if ((selectedMethod !== 'national-shipping' && deliveryDate)) {
                    deliveryDateToSave = deliveryDate.format('YYYY-MM-DD');
                }
    
                onNext({
                    method: selectedMethod,
                    name,
                    store: selectedStore,
                    address: selectedAddress,
                    deliveryDate: deliveryDateToSave,
                    hour: selectedHour,
                });
    
                navigate('/order-summary');
            }
        }
    };

    const handleStoreSelect = (store) => setSelectedStore(store);
    const handleAddressSelect = (address) => setSelectedAddress(address);

    return (
        <section>
            <div className="container mb-75">
                <div className='shipping-method mtb-20'>
                    <button className='btn btn-yellow mb-16' onClick={() => navigate(-1)}>
                        Regresar
                    </button>
                    <h2 className='mb-16'>Elige tu forma de entrega</h2>

                    {/* Store Pickup */}
                    <div
                        className={`shipping-option ${selectedMethod === 'store-pickup' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('store-pickup')}
                    >
                        <div className='first-wrapper'>
                            <span>Recoger en tienda</span>
                            <div className='method_price'>
                                Gratis
                            </div>
                        </div>
                        {selectedMethod === 'store-pickup' && (
                            <form className='store-pickup'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                </div>
                            </form>
                        )}
                    </div>

                    {/* Foreign Shipping */}
                    <div
                        className={`shipping-option ${selectedMethod === 'foreign-shipping' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('foreign-shipping')}
                    >
                        <div className='first-wrapper'>
                            <span>Envío foráneo</span>
                            <div className='method_price'>
                                Gratis
                            </div>
                        </div>
                        {selectedMethod === 'foreign-shipping' && (
                            <form className='foreign-shipping'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                    <p className='mb-16'>Entregar en:</p>
                                    <DropdownForeign onSelect={handleAddressSelect} />
                                </div>
                            </form>
                        )}
                    </div>

                    {/* Pickup Point */}
                    <div
                        className={`shipping-option ${selectedMethod === 'pickup-point' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('pickup-point')}
                    >
                        <div className='first-wrapper'>
                            <span>Punto de entrega</span>
                            <div className='method_price'>
                                Gratis
                            </div>
                        </div>
                        {selectedMethod === 'pickup-point' && (
                            <form className='pickup-point'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                    <p className='mb-16'>Selecciona un punto de entrega:</p>
                                    <DropDownPickUp onSelect={handleAddressSelect} />
                                </div>
                            </form>
                        )}
                    </div>

                    {/* Package Shipping */}
                    <div
                        className={`shipping-option ${selectedMethod === 'package-shipping' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('package-shipping')}
                    >
                        <div className='first-wrapper mb-10'>
                            <span>Envío a domicilio</span>
                            <div className='method_price'>
                                $50.00
                            </div>
                        </div>
                        <p className='mb-5'>Disponible para Pachuca y Mineral de la Reforma. Monto mínimo: $200.</p>

                        {selectedMethod === 'package-shipping' && (
                            <form className='package-shipping'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                    <label className='mtb-16'>Dirección de envío:</label>
                                    <input
                                        type="text"
                                        value={selectedAddress} 
                                        onChange={(e) => setSelectedAddress(e.target.value)} 
                                        placeholder="Ingresa la dirección de envío"
                                        required
                                    />
                                    {/* <DatePicker
                                        format="YYYY-MM-DD"
                                        onChange={(date) => {
                                            setDeliveryDate(date);
                                        }}
                                    /> */}
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        disabledDate={disabledDateForPackageShipping}
                                        onChange={(date) => {
                                            setDeliveryDate(date);
                                        }}
                                    />
                                    <p>¡Importante! Nuestro horario de reparto es de 10:00 a.m. a 03:00 p.m.</p>
                                    <label className='mtb-16'>Agenda tu entrega</label>
                                    <input
                                        type="text"
                                        value={selectedHour}
                                        onChange={(e) => setSelectedHour(e.target.value)}
                                        placeholder="Ejemplo: 11:00 a 01:00"
                                        required
                                    />
                                </div>
                            </form>
                        )}
                    </div>


                    {/* National Shipping */}
                    <div
                        className={`shipping-option ${selectedMethod === 'national-shipping' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('national-shipping')}
                    >
                        <div className='first-wrapper mb-10'>
                            <span>Paquetería</span>
                            <div className='method_price'>
                                $350.00
                            </div>
                        </div>
                        <p className='mb-5'>Envío a toda la república</p>

                        {selectedMethod === 'national-shipping' && (
                            <form className='national-shipping'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                    <label className='mtb-16'>Dirección de envío:</label>
                                    <input
                                        type="text"
                                        value={selectedAddress}
                                        onChange={(e) => setSelectedAddress(e.target.value)}
                                        placeholder="Ingresa la dirección de envío"
                                        required
                                    />
                                    <p className='font-size-xs'>
                                        Las medidas del paquete deben ser 50x50x50 con un peso máximo de 25kg. Si las dimensiones son mayores, cotizamos su envío vía WhatsApp.
                                    </p>
                                </div>
                            </form>
                        )}

                </div>
                </div>
                    <button
                        className='confirm-button'
                        onClick={handleSubmit}
                        disabled={
                            (!selectedMethod || !name) ||
                            (selectedMethod === 'package-shipping' && (!deliveryDate || !selectedAddress || cartTotal < 200)) ||
                            (selectedMethod === 'national-shipping' && (!name || !selectedAddress)) || 
                            (selectedMethod === 'foreign-shipping' && !selectedAddress) ||
                            (selectedMethod === 'pickup-point' && !deliveryDate)
                        }
                    >
                        Confirmar método de envío
                    </button>
            </div>
            <Modal title="¡Inicia sesión!" open={isModalOpen} onOk={() => navigate('/login', { state: { from: '/cart' } })} onCancel={() => setIsModalOpen(false)}>
                <p>¡Inicia sesión para finalizar tu compra!</p>
            </Modal>
        </section>
    );
};

export default ShippingMethod;