import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCart } from './components/context/cartContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './components/context/authContext';
import TimerDisplay from './components/components/cart/TimerDisplay';

// import { Link } from 'react-router-dom';

const Navbar = () => {
    const location = useLocation();
    const { user, signOut } = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const { cart } = useCart();
    const uniqueProductCount = cart.length; // Contar los productos únicos

    const toggleMenu = () => {
    setIsOpen(!isOpen); // Cambiar el estado de isOpen al contrario de su valor actual
};
const navigate = useNavigate();

const handleProfileClick = () => {
    if (user) {
        navigate('/profile');
    } else {
        navigate('/login');
    }
};
return (
<>
    <div className="logo-bar ">
        <img src="https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/order-files/logo-blanco.svg" alt="MRTLogo" />
        <TimerDisplay></TimerDisplay>
    </div>
    <nav className={`navbar-menu ${isOpen ? 'open' : ''}`} >
    <Link className='navbar-item' to="/policies">
    <svg width="24" height="24" viewBox="0 0 24 24"
        fill={location.pathname === '/policies' ? '#AA0000' : '#F9F9F9'} 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9125 3.8625H19.8375C19.575 3.8625 19.3125 3.8625 19.05 3.8625V3C19.05 1.725 18 0.6375 16.6875 0.6375H5.3625C4.05 0.675 3 1.725 3 3V15.825C3 16.3125 3.1875 16.7625 3.525 17.1375L9 22.7625C9.3375 23.1375 9.825 23.325 10.35 23.325H17.2125C18.225 23.325 19.0875 22.5 19.0875 21.45V21.1125L20.8875 17.475C20.9625 17.3625 20.9625 17.25 20.9625 17.1L21 4.95C21 4.3875 20.5125 3.9 19.9125 3.8625ZM19.3125 6.9H17.2125V5.5125C17.925 5.5125 18.6 5.5125 19.3125 5.5125V6.9ZM8.925 20.25L5.85 17.1H8.925V20.25ZM18.225 19.05L17.175 16.9125V8.5875H19.275V16.9125L18.225 19.05Z"         
        fill={location.pathname === '/policies' ? '#AA0000' : '#F9F9F9'} 
        stroke={location.pathname === '/policies' ? '#AA0000' : '#232323' }
        strokeWidth={location.pathname === '/policies' ? '1' : '1.5'}
        />
    </svg>
        <p>Políticas</p>
    </Link>
    {/* <Link className='navbar-item' to="/offers">
    <svg width="24" height="24" viewBox="0 0 24 24"
        fill={location.pathname === '/offers' ? '#AA0000' : '#F9F9F9'} 
        strokeWidth={location.pathname === '/offers' ? '1' : '1.5'}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2375 7.1625L19.7625 13.6875C20.6625 14.5875 20.6625 16.05 19.7625 16.9875L14.0625 22.6875C13.6125 23.1375 13.0125 23.3625 12.4125 23.3625C11.8125 23.3625 11.2125 23.1375 10.7625 22.6875L4.23746 16.1625C3.59996 15.4875 3.37496 14.55 3.71246 13.6875L5.51246 8.925C5.58746 8.8125 5.66246 8.7 5.73746 8.625C5.54996 7.95 5.43746 7.2 5.43746 6.45C5.43746 4.35 6.22496 2.4 7.72496 0.899998C8.06246 0.562498 8.58746 0.562498 8.92496 0.899998C9.26246 1.2375 9.26246 1.7625 8.92496 2.1C7.76246 3.2625 7.12496 4.8 7.12496 6.45C7.12496 6.9375 7.19996 7.4625 7.31246 7.95L10.7625 6.6375C11.625 6.3 12.6 6.525 13.2375 7.1625Z" 
        stroke={location.pathname === '/offers' ? '#AA0000' : '#232323' }
        />
        <path d="M11.925 12.1125C11.475 12.1125 11.1 12.4875 11.1 12.9375V17.1375C11.1 17.5875 11.475 17.9625 11.925 17.9625C12.375 17.9625 12.7875 17.5875 12.7875 17.1375V12.9375C12.75 12.4875 12.375 12.1125 11.925 12.1125Z"
        fill={location.pathname === '/offers' ? '#F9F9F9' : '#232323' }
        />
        <ellipse cx="14.3" cy="15.035" rx="0.8" ry="0.835"         
        fill={location.pathname === '/offers' ? '#F9F9F9' : '#232323' }
        />
        <ellipse cx="9.3" cy="14.835" rx="0.8" ry="0.835" 
        fill={location.pathname === '/offers' ? '#F9F9F9' : '#232323' }
        />
    </svg>
        <p>Ofertas</p>
    </Link> */}
    <Link className='navbar-item' to="/">
        <svg width="24" height="24" viewBox="0 0 24 24" fill={location.pathname === '/' ? '#AA0000' : '#F9F9F9'} xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9 10.3125L12.65 1.3875C12.2 0.900003 11.6375 0.675003 11 0.675003C10.3625 0.675003 9.79999 0.937503 9.34999 1.3875L1.09999 10.3125C0.874987 10.575 0.799987 10.9125 0.949987 11.2125C1.09999 11.5125 1.39999 11.7 1.73749 11.7H2.74999V21.0375C2.74999 22.3125 3.76249 23.325 5.03749 23.325H16.9625C18.2375 23.325 19.25 22.3125 19.25 21.0375V11.7375H20.2625C20.6 11.7375 20.9 11.55 21.05 11.25C21.2 10.95 21.125 10.575 20.9 10.3125ZM12.7625 21.675H9.23749V19.6125C9.23749 18.6375 10.025 17.85 11 17.85C11.975 17.85 12.7625 18.6375 12.7625 19.6125V21.675Z" 
            stroke={location.pathname === '/' ? '#AA0000' : '#232323' }
            strokeWidth={location.pathname === '/' ? '1' : '2'}/>
        </svg>
        <p>Inicio</p>
    </Link>
    <Link className='navbar-item' to="/cart" id='cart-item'>
        <svg width="24" height="24" viewBox="0 0 24 24"
        fill={location.pathname === '/cart' ? '#AA0000' : '#232323'}
        stroke={location.pathname === '/cart' ? '#AA0000' : '#232323' }
        strokeWidth={location.pathname === '/cart' ? '1' : '1.5'}
        xmlns="http://www.w3.org/2000/svg">
            <path d="M18.525 5.025H16.425C16.3875 2.5875 14.4375 0.675 12 0.675C9.56248 0.675 7.61248 2.625 7.57498 5.025H5.47498C4.01248 5.025 2.84998 6.225 2.84998 7.65V18.3C2.84998 21.075 5.09998 23.325 7.87498 23.325H16.125C18.9 23.325 21.15 21.075 21.15 18.3V7.65C21.15 6.1875 19.9875 5.025 18.525 5.025ZM12 2.325C13.5 2.325 14.7 3.525 14.7375 4.9875H9.26248C9.29998 3.525 10.5 2.325 12 2.325Z"
            fill={location.pathname === '/cart' ? '#AA0000' : '#F9F9F9'}
            strokeWidth={location.pathname === '/cart' ? '1' : '1.5'}
            stroke={location.pathname === '/cart' ? '#AA0000' : '#232323' }
            />
        </svg>
        <p>Carrito</p>
        {uniqueProductCount > 0 && <span className="cart-count">{uniqueProductCount}</span>}
    </Link>
    <button className='navbar-item' onClick={handleProfileClick}>
        <svg width="24" height="24" viewBox="0 0 24 24"
        fill={location.pathname === '/profile' ? '#AA0000' : '#232323'}
        // stroke={location.pathname === '/profile' ? '#AA0000' : '#232323' }

        xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_598_18889)">
            <path d="M12 5.8125C9.9375 5.8125 8.25 7.4625 8.25 9.45C8.25 11.4375 9.9375 13.125 12 13.125C14.0625 13.125 15.75 11.475 15.75 9.4875C15.75 7.5 14.0625 5.8125 12 5.8125Z"
            fill={location.pathname === '/profile' ? '#AA0000' : '#232323'}
            // stroke={location.pathname === '/profile' ? '#AA0000' : '#232323' }
            />
            <path d="M12 0.675C5.73755 0.675 0.675049 5.7375 0.675049 12C0.675049 18.2625 5.73755 23.3625 12 23.3625C18.2626 23.3625 23.3625 18.2625 23.3625 12C23.3625 5.7375 18.2626 0.675 12 0.675ZM18.3 19.3125V17.4C18.3 15.2625 16.575 13.5375 14.4375 13.5375H9.56255C7.42505 13.5375 5.70005 15.2625 5.70005 17.4V19.3125C3.63755 17.55 2.32505 14.925 2.32505 12C2.36255 6.675 6.67505 2.3625 12 2.3625C17.3251 2.3625 21.675 6.7125 21.675 12.0375C21.675 14.925 20.3625 17.55 18.3 19.3125Z"
            fill={location.pathname === '/profile' ? '#AA0000' : '#232323'}
            // stroke={location.pathname === '/profile' ? '#AA0000' : '#232323' }
            />
            </g>
            <defs>
            <clipPath id="clip0_598_18889">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>
        <p>Perfil</p>
    </button>
    {/* <button className='navbar-item' onClick={signOut}>
        <p>Cerrar sesión</p>
      </button> */}
    </nav>
</>
);
};

export default Navbar;
